<template>
  <div class="order-history">
    <h1 class="order-history__page-title">THE PROCESS OF CREATING A SHYRDAK</h1>
    <div class="order-history__carousel-block">
      <v-carousel
          :interval="3000"
          cycle
          class="slider carousel"
          :show-arrows="true"
          :height="600"
      >
        <v-carousel-item
            v-for="(image, i) in photosOfProducts"
            :key="i"
        >
          <div class="order-history__carousel-img-block">
            <div class="order-history__carousel-img">
              <img
                  :src="image"
                  :alt="getImageName(image)" :title="getImageName(image)" 

              >
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>

    <div class="order-history__content">
      <div
          v-for="(step, i) in stepsOfCreatingProduct"
          :key="i"
          class="order-history__step-item"
      >
        <h2 class="order-history__title">
          <span class="order-history__step">{{ step.priority }}</span>
          {{ step.title }}
        </h2>
        <div
            class="order-history__paragraph-block"
            v-html="step.description"
        />

        <div class="order-history__content-images">
          <div
              v-for="(image, i) in step.images"
              :key="i"
              class="order-history__content-img-block"
          >
            <img
                :src="image.file_url"
                :alt="getImageName(image.file_url)" :title="getImageName(image.file_url)" 

            >
          </div>
          <template v-if="step.videos.length">
            <div
                v-for="(video, i) in step.videos"
                :key="i"
                class="order-history__video-block"
            >
              <video controls>
                <source :src="video.file_url" type="video/webm" />
                <source :src="video.file_url" type="video/mp4" />
              </video>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/core/services/api.service'
import { getImageName } from '@/utils/utils'

export default {
  name: "OrderHistory",
  data () {
    return {
      stepsOfCreatingProduct: [],
      photosOfProducts: [],
    }
  },
  created() {
    const id = this.$route.params.id

    if (+id) {
      this.getArtisanOrderHistoryById(id)
      this.getPassportsForProduct(id)
    }
  },
  methods: {
    getImageName,

    getArtisanOrderHistoryById(id) {
      axios.get(`/specialgoods/artisan/orders/${id}/history`)
      .then((res) => {
        this.stepsOfCreatingProduct = res.data.data.sort((a, b) => a.priority - b.priority)
      })
    },
    getPassportsForProduct (id) {
      axios.get(`/specialgoods/artisan/orders/${id}/passports`)
        .then((res) => {
          let photos = []

          res.data.data.forEach((item) => {
            photos.push(item.photo_url)
          })

          this.photosOfProducts = photos
        })
    }
  }
}
</script>

<style lang="scss">
.order-history {
  margin: 150px auto;
  width: 980px;

  &__page-title {
    font-size: 37px;
    margin: 0 0 40px 0;
    color: #666;
    text-align: center;
  }
  &__carousel {
    &-img {
      width: 60%;
      height: auto;
      margin: auto;

      &-block {
        background-color: rgb(219, 219, 207);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__video-block {
    display: flex;
    align-items: center;

    video {
      height: auto;
      width: 100%;
    }
  }
  &__title {
    font-weight: 700 !important;
    color: #666 !important;
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-left: -33px;
  }
  &__step {
    background: #ccc;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
    border-radius: 50%;
  }
  &__step-item {
    margin: 50px 0;
  }
  &__paragraph-block {
    margin-bottom: 20px;

    p > span, span {
      font-weight: 400 !important;
      color: #757474 !important;
      font-size: 15px !important;
      line-height: 20.4px !important;
    }
  }
  &__content {
    margin: 20px 0;
    border-left: 1px solid #ddd;
    padding-left: 20px;


    &-images {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 260px;
      gap: 10px;
    }
    &-img-block {
      width: 100%;
      height: 100%;
      border: 1px solid #ccc;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__certificate {
    &-block {
      width: 800px;
      margin: 40px auto 0;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    height: 1200px;

    &-title {
      margin-top: 40px;
      font-size: 30px;
      text-align: center;
      color: #666;
    }
  }
  &__carousel-item {

    img {
      pointer-events: none;
    }
  }
}
</style>
